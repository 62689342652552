import React, { useState, useContext } from 'react';
import FacebookLogin from 'react-facebook-login';
import { Card, Image } from 'react-bootstrap';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { useHistory } from 'react-router-dom';

const Facebook = props => {
	const [login, setLogin] = useState(false);
	const [data, setData] = useState({});
	const [picture, setPicture] = useState('');
	const { authDispatch, loginFacebookUser } = useContext(AuthContext);
	let history = useHistory();

	const responseFacebook = response => {
		if (!response || response.status === 'unknown') {
			setLogin(false);
			return;
		}

		setData(response);
		setPicture(response.picture.data.url);
		if (response.accessToken) {
			loginFacebookUser(response, history).then(err => {
				if (err) {
					props.handleError(err);
				}
			});
		} else {
			setLogin(false);
		}
	};

	return (
		<div className='container' style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', display: 'flex' }}>
			{/* <Card style={{ width: '600px' }}> */}
			<Card style={{ width: 'max-content', border: 'none' }}>
				<div>
					{!login && (
						<FacebookLogin
							appId='1921032698198404'
							fields='name,email,picture'
							scope='public_profile,email,user_friends'
							callback={responseFacebook}
							icon='fa-facebook'
						/>
					)}
					{login && <Image src={picture} roundedCircle />}
				</div>

				{login && (
					<Card.Body>
						<Card.Title>{data.name}</Card.Title>
						<Card.Text>{data.email}</Card.Text>
					</Card.Body>
				)}
			</Card>
		</div>
	);
};

export default Facebook;
